var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{staticClass:"app"},[_c('v-main',{class:{ 'pt-0': _vm.isWelcome }},[_c('v-progress-linear',{attrs:{"absolute":"","active":_vm.loading,"indeterminate":"","height":"5"}}),_c('WorkQueueBanner'),_c('v-slide-y-transition',[(_vm.showNav)?_c('ApplicationNavBar'):_vm._e()],1),(
        ((_vm.isCloud && _vm.isAuthenticated) || _vm.isServer) &&
          _vm.loadedComponents > 0 &&
          !_vm.isWelcome &&
          _vm.$route.name !== 'team-switched'
      )?_c('TeamSideNav'):_vm._e(),_c('v-fade-transition',{attrs:{"mode":"out-in"}},[_c('router-view',{staticClass:"router-view",class:{
          'full-page': _vm.fullPageRoute,
          'sm-and-down-bottom-padding': _vm.$vuetify.breakpoint.smAndDown
        }})],1),(_vm.error)?_c('v-container',{staticClass:"fill-height",attrs:{"fluid":"","justify-center":""}},[_c('v-card',{staticClass:"error--login"},[_c('v-card-text',[_vm._v(_vm._s(_vm.error))])],1)],1):_vm._e(),(_vm.$vuetify.breakpoint.xsOnly && _vm.showNav)?_c('GlobalSearch'):_vm._e(),_c('v-slide-y-reverse-transition',[(!_vm.fullPageRoute && _vm.showFooter && !_vm.isWelcome)?_c('Footer'):_vm._e()],1)],1),(_vm.getAlert.alertShow)?_c('Alert',{attrs:{"type":_vm.getAlert.alertType,"message":_vm.getAlert.alertMessage,"alert-link":_vm.getAlert.alertLink,"link-text":_vm.getAlert.linkText,"nudge-bottom":_vm.paused,"timeout":12000,"alert-copy":_vm.getAlert.alertCopy},model:{value:(_vm.getAlert.alertShow),callback:function ($$v) {_vm.$set(_vm.getAlert, "alertShow", $$v)},expression:"getAlert.alertShow"}}):_vm._e(),_c('VSnackbars')],1)}
var staticRenderFns = []

export { render, staticRenderFns }