var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-center justify-end global-search-container",class:{ 'justify-center': _vm.active }},[_c('v-btn',{staticClass:"mx-1 global-search-activator cursor-pointer",class:{
      active: _vm.active,
      'navbar-icon': _vm.$vuetify.breakpoint.smAndUp,
      fixed: _vm.$vuetify.breakpoint.xsOnly
    },attrs:{"icon":_vm.$vuetify.breakpoint.smAndUp,"title":"Search your team for projects, flows, and runs","absolute":_vm.$vuetify.breakpoint.xsOnly,"fab":_vm.$vuetify.breakpoint.xsOnly,"elevation":_vm.$vuetify.breakpoint.xsOnly ? 2 : null},nativeOn:{"click":function($event){return _vm._activate($event)}}},[_c('i',{staticClass:"fad fa-search fa-2x nav-bar-duotone-icon"})]),_c('div',{staticClass:"global-search",class:{ active: _vm.active, fixed: _vm.$vuetify.breakpoint.xsOnly }},[(_vm.active)?_c('v-autocomplete',{ref:"global-search",attrs:{"single-line":"","dense":"","outlined":"","multiple":"","dark":_vm.$vuetify.breakpoint.smAndUp,"clearable":"","menu-props":{
        closeOnContentClick: false,
        closeOnClick: false
      },"items":_vm.entries,"loading":_vm.isLoading ? 'green accent-3' : false,"search-input":_vm.search,"filter":_vm.searchFilter,"label":"Search","placeholder":"Search","item-text":"name","hide-details":"","clear-icon":"close","open-on-clear":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"blur":_vm._deactivate,"clear":function($event){_vm.results = []},"focus":function($event){_vm.results = []
        _vm.handleSearch(_vm.input)}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [(!_vm.connected)?_c('div',{staticClass:"text-subtitle-1 pa-4",staticStyle:{"width":"500px"}},[_c('div',{staticClass:"font-weight-light"},[_vm._v(" You aren't connected, so you won't be able to search for anything... ")]),_c('v-divider',{staticClass:"my-6 mx-6"}),_c('HavingTrouble')],1):(_vm.input == null)?_c('v-list-item',[_c('v-list-item-title',{staticClass:"text-subtitle-1 font-weight-light"},[_vm._v(" Type to search for a "),_c('strong',[_vm._v("Project")]),_vm._v(", "),_c('strong',[_vm._v("Flow")]),_vm._v(", "),_c('strong',[_vm._v("Task")]),_vm._v(", or "),_c('strong',[_vm._v("Run")])])],1):(_vm.isLoading)?_c('v-list-item',[_c('v-list-item-title',[_vm._v(" Searching... ")])],1):(_vm.mResult)?_c('MResult'):_c('v-list-item',[_c('v-list-item-title',[_vm._v(" No results matched your search. ")])],1)]},proxy:true},{key:"item",fn:function(data){return [(data)?_c('GlobalSearchIcon',{attrs:{"type":data.item.__typename}}):_vm._e(),(data)?_c('GlobalSearchResult',{ref:"result",attrs:{"search-result":data.item,"parent":data.parent}}):_vm._e()]}}],null,false,2808999954),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }